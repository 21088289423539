<template>
  <Page
      title="Тикеты"
  >
    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Тикеты юзеров</h4>
        </div>
        <Table
            parse-url="/backoffice/admin/tickets"
            :columnList="ticketsTableColumns"
            default-sort="lastMessageDate"
            searchPlaceholderText="Введите почту, категорию, заголовок или айди"
            @handle-data="ticketsTableData = $event"
        >
          <tr v-for="ticket, index in ticketsTableData" :key="'user' + index">
            <td class="hash">
              <router-link :to="'/back-office/admin/tickets/' + ticket._id">
                {{ ticket.id }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/back-office/admin/users/' + ticket.userId">
                {{ ticket.username }}
              </router-link>
            </td>
            <td>{{ ticket.category }}</td>
            <td :class="{ 'color-danger': ticket.isClosed, 'color-warning': !ticket.isAnswered && !ticket.isClosed, 'color-success': ticket.isAnswered && !ticket.isClosed }">{{ ticket.isClosed ? 'Закрыт' : ticket.isAnswered ? 'Отвечен' : 'Ожидает ответа' }}</td>
            <td>{{ ticket.lastMessageDate | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import Page from "@/components/backoffice/Page";

export default {
  name: "Main",
  components: {
    Table,
    Page
  },
  data() {
    return {
      ticketsTableData: [],
      ticketsTableColumns: [
        {
          name: 'id',
          title: 'Айди',
          isSortable: true
        },
        {
          name: 'username',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'category',
          title: 'Категория',
          isSortable: true
        },
        {
          name: 'isAnswered',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата посл. сообщения',
          isSortable: true
        },
      ],
    }
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>